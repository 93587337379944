<template>
  <div class="design">
    <Banner :list="banners"></Banner>
    <div class="design-main container">
		<!-- 菜单 -->
	  <menulist></menulist>
      <!-- <passage></passage> -->
	  
	  <div class="qualification">
	    
	    <div class="qualification-main">
	    
	      <div class="serve">
	        <div class="main-title">
	          <h1>优普道 让资质下证变得更便捷安全</h1>
	          <h3>办理流程的一体化，让建企省时省心，招投标变得更简单</h3>
	        </div>
	        <div class="list">
	          <template v-for="(item, index) in childList">
	            <div
	              :key="index"
	              class="item"
	              :style="{
	                backgroundImage: 'url(' + item.image_url + ')',
	                backgroundSize: 'cover',
	              }"
				  @click.self="navDetails(item.skip_url)"
	            >
	              <div class="info">
	                <h3>{{ item.name }}</h3>
	                <p class="mt-1">{{ item.description }}</p>
	              </div>
	              <a-button class="btn" @click="$toConnectLink">
	                立即咨询>>
	              </a-button>
	            </div>
	          </template>
	        </div>
	        <div class="m-list">
	          <div class="serve-container swiper-container swiper-m-list">
	            <div class="swiper-wrapper">
	              <template
	                v-for="(item, index) in Math.floor(childList.length / 4)"
	              >
	                <div class="swiper-slide" :key="index">
	                  <template v-for="(child, idx) in childList">
	                    <div
	                      :key="idx"
	                      v-if="idx >= index * 4 && idx < index * 4 + 4"
	                      class="item"
	                      :style="{
	                        backgroundImage: 'url(' + child.image_url + ')',
	                        backgroundSize: 'cover',
	                      }"
	                    >
	                      <div class="info">
	                        <h3>{{ child.name }}</h3>
	                        <p class="mt-1">{{ child.description }}</p>
	                      </div>
	                    <!--  <a-button class="btn" @click="$toConnectLink">
	                        立即咨询>>
	                      </a-button> -->
	                    </div>
	                  </template>
	                </div>
	              </template>
	            </div>
	            <div class="swiper-pagination"></div>
	          </div>
	        </div>
	      </div>
		</div>
	   </div>
	</div>   
	<div class="m-list-btn" @click="$toConnectLink">咨询顾问</div>
		<!-- tag 选项 -->
	<scenes></scenes>
	   <!-- list 视频 -->
	<videolist ifcolor='aa' :fromData="fromData"></videolist>
	<!-- 滚动 + 留言 -->
	<strengthnew></strengthnew>
	<!-- tab  选择优普道-->
	<choose></choose>
	<!-- 新闻 -->
	<news ifcolor='aa'></news>
	
	<div class="design-main container">
	   <!-- 帮您少走弯路 专业稳妥放心  icon list -->
     <!-- <div class="serve">
        <div class="main-title">
          <h1>帮您少走弯路 专业稳妥放心</h1>
          <h3>
            专家一对一服务 项目进展可实时监控<br />工程设计资质标准分为甲、乙两个等级，个别资质分为甲、乙、丙
          </h3>
        </div>
        <div class="list">
          <template v-for="(item, index) in childList">
            <div
              class="item"
              :key="index"
              :class="{ active: serveActive == index }"
              @mouseenter="hoverServe(index)"
              @mouseleave="hoverServe"
            >
              <div class="img-box">
                <img
                  :src="serveActive == index ? item.activeImg : item.serveImg"
                />
              </div>
              <div class="info mt-2">
                <h3>{{ item.name }}</h3>
                <div class="second mt-1 text-center">
                  <p
                    v-for="(child, idx) in item.description.split('；')"
                    :key="idx"
                  >
                    {{ child }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div> -->
	  
<!--      <div class="connectNew">
        <div class="item" @click="$toConnectLink">
          <img src="@/assets/image/design/054.png" alt="" />
          <p>我要新办</p>
        </div>
        <div class="item" @click="$toConnectLink">
          <img src="@/assets/image/design/055.png" alt="" />
          <p>我要增项</p>
        </div>
        <div class="item" @click="$toConnectLink">
          <img src="@/assets/image/design/056.png" alt="" />
          <p>我要升级</p>
        </div>
        <div class="item" @click="$toConnectLink">
          <img src="@/assets/image/design/057.png" alt="" />
          <p>我要延期</p>
        </div>
      </div>
      <div class="descignCase">
        <div class="main-title">
          <h1>成功案例</h1>
        </div>
        <div class="descignCase-main">
          <productBanner></productBanner>
        </div>
      </div>
      <div class="customer">
        <template v-for="(item, index) in allCase">
          <div class="item" @click="toCase" :key="index">
            <img :src="item" alt="" />
          </div>
        </template>
      </div> -->
      <!-- <questions></questions> -->
	  <strength></strength>
    </div>
    <message></message>
  </div>
</template>

<script>
import Banner from "@/components/common/banner";
import menulist from '@/components/common/service/menu.vue'
import passage from "@/components/common/passage";
import scenes from '@/components/common/service/scenes.vue'

import message from "@/components/common/message";
import choose from "@/components/common/choose";

import questions from "@/components/common/questions";
import productBanner from "@/components/home/product.vue";
import { WOW } from "wowjs";
export default {
  components: {
    Banner,
	menulist,
    passage,
    message,
    choose,
    productBanner,
    questions,
	scenes,
  },
  data() {
    return {
	  fromData:{subdoadmin: '',launchPage: 40,classify: 20,scope: 0},
      allCase: [
        require("@/assets/image/case/1.png"),
        require("@/assets/image/case/2.png"),
        require("@/assets/image/case/4.png"),
        require("@/assets/image/case/5.png"),
        require("@/assets/image/case/6.png"),
        require("@/assets/image/case/7.png"),
        require("@/assets/image/case/8.png"),
        require("@/assets/image/case/9.png"),
        require("@/assets/image/case/10.png"),
        require("@/assets/image/case/11.png"),
        require("@/assets/image/case/14.png"),
        require("@/assets/image/case/15.png"),
        require("@/assets/image/case/16.png"),
        require("@/assets/image/case/20.png"),
        require("@/assets/image/case/21.png"),
      ],
      serveActive: -1,
      banners: {
        title: "8项建筑工程企业设计资质办理",
        desc: "1000+专业顾问保航护驾    30+子公司战略布局",
        list: [require("@/assets/image/banner/3.jpg")],
      },
      serve: [
        {
          serveImg: require("@/assets/image/design/030.png"),
          activeImg: require("@/assets/image/design/042.png"),
          name: "电力行业工程设计",
          child: ["送电工程", "变电工程", "火力发电", "水力发电"],
        },
        {
          serveImg: require("@/assets/image/design/031.png"),
          activeImg: require("@/assets/image/design/043.png"),
          name: "建筑工程设计",
          child: ["一般公共建筑", "住宅宿舍建筑", "工业厂房建筑", "地下工程"],
        },
        {
          serveImg: require("@/assets/image/design/032.png"),
          activeImg: require("@/assets/image/design/044.png"),
          name: "人防工程设计",
          child: [
            "各级人防工程",
            "人员掩蔽工程",
            "防空专业队工程",
            "人防配套工程",
          ],
        },
        {
          serveImg: require("@/assets/image/design/033.png"),
          activeImg: require("@/assets/image/design/045.png"),
          name: "市政行业工程设计",
          child: [
            "各级人防工程",
            "人员掩蔽工程",
            "防空专业队工程",
            "人防配套工程",
          ],
        },
        {
          serveImg: require("@/assets/image/design/034.png"),
          activeImg: require("@/assets/image/design/046.png"),
          name: "公路行业工程设计",
          child: ["公路工程", "特大桥梁工程", "交通工程", "特长隧道工程"],
        },
        {
          serveImg: require("@/assets/image/design/035.png"),
          activeImg: require("@/assets/image/design/047.png"),
          name: "建筑装饰工程设计专项",
          child: ["室内设计", "给水排水设计", "环境艺术设计", "暖通空调设计"],
        },
        {
          serveImg: require("@/assets/image/design/036.png"),
          activeImg: require("@/assets/image/design/048.png"),
          name: "建筑智能化系统化设计专项",
          child: [
            "通信系统工程",
            "安防系统工程",
            "设备监控系统工程",
            "综合布线及网络工程",
          ],
        },
        {
          serveImg: require("@/assets/image/design/037.png"),
          activeImg: require("@/assets/image/design/049.png"),
          name: "建筑幕墙工程设计专项",
          child: ["玻璃幕墙", "点支撑玻璃幕墙", "单元式幕墙", "采光屋顶幕墙"],
        },
        {
          serveImg: require("@/assets/image/design/038.png"),
          activeImg: require("@/assets/image/design/050.png"),
          name: "风景园林工程设计专项",
          child: [
            "城市园林设计",
            "园林景点建筑",
            "风景园林道路工程",
            "园林配套照明景观",
          ],
        },
        {
          serveImg: require("@/assets/image/design/039.png"),
          activeImg: require("@/assets/image/design/051.png"),
          name: "照明工程设计专项",
          child: [
            "道路街道照明",
            "住宅区公共照明",
            "景观建筑照明",
            "照明供配电工程",
          ],
        },
        {
          serveImg: require("@/assets/image/design/040.png"),
          activeImg: require("@/assets/image/design/052.png"),
          name: "消防设施工程设计专项",
          child: [
            "自动喷水灭火系统",
            "防烟排烟系统",
            "火警防控系统",
            "消火栓系统",
          ],
        },
        {
          serveImg: require("@/assets/image/design/041.png"),
          activeImg: require("@/assets/image/design/053.png"),
          name: "其他设计类资质",
          child: ["轻型钢结构工程设计专项", "环境工程设计专项"],
        },
      ],
      chooseMenu: [
        {
          name: "价格公开透明",
          sname: "The price is open and transparent",
          content: [],
        },
        {
          name: "企业专属人才库",
          sname: "Enterprise exclusive talent pool",
        },
        {
          name: "流程明确、办理周期短",
          sname: "Clear process and short processing cycle",
        },
        {
          name: "专业顾问团队",
          sname: "Clear process and short processing cycle",
        },
      ],
      childList: [],
    };
  },
  methods: {
	initStrenghSwiper() {
	  	var strenghSwiper = new Swiper(".swiper-m-list", {
	  		slidesPerView: "auto",
	  		spaceBetween: 20,
	  		loop: true,
	  		speed: 2000,
	  		autoplay: 2000,
			pagination : '.swiper-pagination'
	  	});
	},
	navDetails(url){
		console.log(url);
		window.open(url);
	},
    toCase() {
      this.$router.push("case");
    },
    getBannerList() {
      this.$api
        .getBannerList({
          // 10首页  20
          launchPage: 40,
          launchFacility: 10,
        })
        .then((res) => {
          this.banners.list = res.data.list;
        });
    },
    getCategoryChild() {
      this.$api
        .getCategoryChild({
          pid: 93,
        })
        .then((res) => {
          this.serve.forEach((item, index) => {
            res.data[index].activeImg = item.activeImg;
            res.data[index].serveImg = item.serveImg;
          });
          this.childList = res.data;
		  this.$nextTick(()=>{
			 this.initStrenghSwiper(); 
		  })
		  
        });
    },
    hoverServe(index) {
      this.serveActive = index;
    },
    leverServe() {
      this.serveActive = -1;
    },
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
	
	this.getBannerList();
	this.getCategoryChild();
  },
  created() {
 
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "HYYaKuHeiW";
  src: url(../../assets/font/HYYaKuHeiW.ttf);
  font-weight: normal;
  font-style: normal;
}

@import "@/assets/css/design.scss";
@import "@/assets/css/contract.scss";
</style>
