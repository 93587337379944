<template>
  <div class="message">
    <div class="message-main container">
      <div class="title py-3">
        您在资质办理过程中遇到了什么问题? 我们愿为您排忧解难!
      </div>
      <div class="content">
        <a-input
          class="name"
          v-model="username"
          type="text"
          placeholder="请输入您的姓名"
        >
          <img src="@/assets/image/message1.png" slot="prefix" alt="" />
        </a-input>
        <div class="line"></div>
        <a-input
          class="phone"
          v-model="phone"
          type="text"
          onkeyup="value=value.replace(/[^\d]/g,'')"
          placeholder="请输入联系电话"
        >
          <img src="@/assets/image/message2.png" slot="prefix" alt="" />
        </a-input>
        <div class="line"></div>
        <a-input
          class="question"
          v-model="question"
          type="text"
          placeholder="请输入遇到的问题"
        >
          <img src="@/assets/image/message3.png" slot="prefix" alt="" />
        </a-input>
        <a-button type="primary" @click="setMessage"> 留言 </a-button>
      </div>
      <div class="m-content">
        <a-button class="btn" type="primary" @click="openMessage">
          留言咨询
        </a-button>
      </div>
      <div class="popMessage" v-show="showMessage">
        <div class="m-content">
          <div class="name">
            <div class="img-box">
              <img src="@/assets/image/message1.png" slot="prefix" alt="" />
            </div>
            <a-input
              v-model="username"
              type="text"
              placeholder="请输入您的姓名"
            >
            </a-input>
          </div>
          <div class="phone">
            <div class="img-box">
              <img src="@/assets/image/message2.png" slot="prefix" alt="" />
            </div>
            <a-input
              v-model="phone"
              type="text"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              placeholder="请输入联系电话"
            >
            </a-input>
          </div>
          <div class="question">
            <a-textarea
              class="m-textarea"
              v-model="question"
              placeholder="请输入留言内容"
              :rows="4"
            />
          </div>

          <a-button type="primary" @click="setMessage"> 留言 </a-button>
        </div>
        <img
          @click="closeMessage"
          class="close"
          src="@/assets/image/closemessage.png"
          slot="prefix"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMessage: false,
      username: "",
      phone: "",
      question: "",
    };
  },
  methods: {
    closeMessage() {
      this.showMessage = false;
      document.body.style.overflow = "auto";
    },
    openMessage() {
      this.showMessage = true;
      document.body.style.overflow = "hidden";
    },
    setMessage() {
      let source_url = window.location.href;
      if (this.username == "") {
        this.$message.error("请输入您的姓名");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$message.error("请输入正确手机号");
        return;
      }
      if (this.question == "") {
        this.$message.error("请输入遇到的问题");
        return;
      }
      let data = {
        channel: 10,
        username: this.username,
        phone: this.phone,
        requirements: this.question,
        source_url: source_url,
      };
      if (this.$mobile) {
        data.channel = 20;
      }
      this.$api.applyOffer(data).then((res) => {
        this.$message.success("留言提交成功，稍后专业顾问将为你答疑解惑");
        this.question = "";
        this.username = "";
        this.phone = "";
        document.body.style.overflow = "auto";
        this.showMessage = false;
      });
    },
  },
};
</script>

<style lang="scss">
.message {
  padding-bottom: 60px;
  background: url(../../assets/image/message-bg.png) no-repeat;
  background-color: #fff;
  background-size: cover;

  background-position: center;

  &-main {
    .title {
      text-align: center;
      font-size: 35px;
      font-family: HYYaKuHeiW;
      font-weight: 400;
      color: #ffffff;
      -webkit-text-stroke: 1px #3a308e;
      text-stroke: 1px #3a308e;
    }

    .content {
      width: 94%;
      margin: 0 auto;
      height: 50px;
      @include flex-center();
      border: 1px solid #ff9a00;
      border-radius: 26px;
      overflow: hidden;
      background: #ffffff;

      .ant-input {
        height: 100%;
        border: none;
        border-radius: 0;
        position: relative;
        text-indent: 10px;
      }

      .line {
        width: 1px;
        height: 20px;
        background: #eaecf4;
      }

      .ant-input:focus {
        box-shadow: none;
      }

      .name {
        width: 25%;
      }

      .phone {
        width: 25%;
      }

      .question {
        width: 40%;
      }

      .ant-btn-primary {
        width: 10%;
        height: 100%;
        background: linear-gradient(0deg, #f85f0c 0%, #ff9000 100%);
        border-radius: 0;
        border: none;
        font-size: 22px;
      }
    }
    .m-content {
      display: none;
    }
  }
}
@media only screen and (max-width: 750px) {
  .message {
    padding-bottom: 60px;
    background: url(../../assets/image/message-bg.png) no-repeat;
    background-color: #fff;
    background-size: cover;

    background-position: center;

    &-main {
      .title {
        text-align: center;
        font-size: 35px;
        font-family: HYYaKuHeiW;
        font-weight: 400;
        color: #ffffff;
        -webkit-text-stroke: 1px #3a308e;
        text-stroke: 1px #3a308e;
      }

      .content {
        display: none;
      }
      .m-content {
        display: block;
      }
    }
  }
}
@media only screen and (max-width: 481px) {
  .message {
    padding-bottom: 60px;
    background: url(../../assets/image/message-bg.png) no-repeat;
    background-color: #fff;
    background-size: cover;

    background-position: center;

    &-main {
      .title {
        text-align: start;
        font-size: 35px;
        font-family: HYYaKuHeiW;
        font-weight: 400;
        color: #ffffff;
      }

      .content {
        display: none;
      }
      .m-content {
        display: block;
        .btn {
          width: 201px;
          height: 46px;
          border: 1px solid #ffb026;
          background: linear-gradient(0deg, #e66c2f 0%, #ff952c 100%);
          border-radius: 23px;
          font-size: 23px;
          color: #fefefe;
        }
      }
      .popMessage {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 99;
        background: rgba($color: #000000, $alpha: 0.8);
        @include flex-center(column);
        .m-content {
          width: 80%;
          background: #ffffff;
          border-radius: 10px;
          padding: 50px 20px;
          .ant-input {
            padding: 0;
            height: 48px;
            border: none;
            border-radius: 0;
            position: relative;
            text-indent: 10px;
            border-bottom: 1px solid #d6d6d6;
          }
          .name,
          .phone {
            margin-bottom: 28px;
            @include flex-start();
            .img-box {
              width: 10%;
              @include flex-center();
            }
          }
          .ant-input:focus {
            box-shadow: none;
          }
          .m-textarea {
            padding: 20px 10px;
            width: 98%;
            margin: 0 auto;
            min-height: 158px;
            background: #fafafa;
            border: 1px solid #e6e6e6;
          }
          .question {
            @include flex-center();
          }

          .ant-btn {
            width: 90%;
            height: 46px;
            margin: 30px auto 0;
            display: block;
            border: 1px solid #ffb026;
            background: linear-gradient(0deg, #e66c2f 0%, #ff952c 100%);
            border-radius: 23px;
            font-size: 20px;
          }
        }
        .close {
          margin-top: 24px;
          width: 42px;
        }
      }
    }
  }
}
</style>
