<template>
	<div class="home-scenes py-6">
		<div class="container">
			<div class="main-title">
				<h1 class="name">建筑资质服务全场景覆盖</h1>
				<h3 class="same">致力于让客户在一个平台解决所有资质业务需求</h3>
			</div>
			<div class="home-scenes-main">
				<div class="scenes-menu">
					<div class="list">
						<template v-for="(item, index) in categoryList">
							<div class="item" :class="{ active: sencesActive == index }" @mouseenter="slectScenes(index, item.id)" :key="index">
								{{ item.name }}
							</div>
						</template>
					</div>
					<div class="more" @click="$toConnectLink">立即订制 >></div>
				</div>
				<div class="scenes-content">
					<div class="scenes-img mr-4 bg-cent">
						<img src="@/assets/image/servebg.jpg" />
					</div>
					<div class="list">
						<template v-if="intelligentList.length !== 0">
							<template v-for="(item, index) in intelligentList">
								<div class="item" :key="index">
									<div class="top">
										<h3 class="title m-0">{{ item.name }}</h3>
										<a-button class="more" @click="$toConnectLink">
											立即咨询
										</a-button>
									</div>
									<ul class="content mt-3">
										<li>发布者：{{ item.publisher }}</li>
										<li>{{ item.publish_time_name }}发布</li>
										<li>所在地区：{{ item.district }}</li>
										<li>
											状态：<span>{{ item.trade_state_name }}</span>
										</li>
									</ul>
								</div>
							</template>
						</template>
						<template v-else>
							<div class="noDate">
								<img src="@/assets/image/noDate.png" alt="" />
								<p>没有数据</p>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				categoryList: [],
				intelligentList: [],
				sencesActive: 0
			}
		},
		methods: {
			slectScenes(index, id) {
				this.sencesActive = index;
				this.industry = id;
				this.getIntelligentList();
				// 接分类接口
			},
			getCategoryList() {
				this.$api.getArticleProfession().then((res) => {
					this.categoryList = res.data;
					this.industry = this.categoryList[0].id;
					this.getIntelligentList();
				});
			},
			getIntelligentList() {
				this.$api
					.getIntelligentList({
						industry: this.industry,
						limit: 4,
					})
					.then((res) => {
						this.intelligentList = res.data.list;
					});
			}
		},
		created() {
			this.getCategoryList();
		}
	}
</script>

<style scoped lang="scss">
	.main-title {
		padding-top: 0;
		text-align: center;

		h1 {
			font-size: 36px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			color: #383D41;
		}

		h3 {
			font-size: 20px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #727585;
		}
	}

	.home-scenes {
		background-color: #FAFAFB;
		background-image: url(../../../assets/image/serve/fg-bg.png);
		background-size: 100%;
		&-main {
			.scenes-menu {
				@include flex-between();

				h4 {
					font-size: 20px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #383D41;
					margin-right: 25px;
				}

				.list {
					padding: 0 20px;
					@include flex-start();
					border-bottom: 1px solid #E3E5EF;

					.item {
						padding: 0 20px 20px;
						font-size: 16px;
						color: $descColor;
						line-height: 22px;
						cursor: pointer;
					}

					.active {
						color: $primary-color;
						position: relative;

						&::after {
							content: '';
							display: block;
							width: 50px;
							height: 3px;
							background: $primary-color;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
						}
					}

				}

				.more {
					font-size: 18px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: $descColor;
					line-height: 22px;
					cursor: pointer;
				}
			}

			.scenes-content {
				width: 100%;
				margin-top: 45px;
				@include flex-between();
				align-items: flex-start;

				.scenes-img {
					width: 18%;
					height: 378px;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.list {
					width: 100%;
					// width: 80%;
					@include flex-between();
					// flex-direction:column;
					align-items: flex-start;
					flex-wrap: wrap;
					// margin-bottom: 80px;

					.item {
						width: 49%;
						height: 178px;
						padding: 16px 26px;
						border: 1px solid #D9DCE8;
						border-radius: 8px;
						transition: all 0.5s;

						&:nth-child(3) {
							margin-top: 20px;
						}

						&:nth-child(4) {
							margin-top: 20px;
						}

						&:hover {
							transform: scale(1.02);
							transition: all 0.5s;
						}

						.top {
							@include flex-between();

							.title {
								width: 70%;
								@include ellipsis();
								font-size: 20px;
								color: $text-color;
							}

							.more {
								width: 103px;
								height: 30px;
								background: #FFFFFF;
								border: 1px solid $primary-color;
								border-radius: 8px;
								color: $primary-color;
							}
						}

						.content {

							@include flex-between();
							flex-wrap: wrap;

							li {
								width: 30%;
								padding: 10px 0;
								@include ellipsis();

								span {
									color: #FF3B0E;
								}

								&:nth-child(2n+1) {
									width: 70%;
								}
							}
						}
					}

					// 首页 建筑企业股权转让
					.list-cont {
						width: 24.5%;
						height: 200px;
						padding: 18px 12px;
						border: 1px solid #D9DCE8;

						// margin-left: 7px;
						.list-cont-img {
							width: 114px;
							height: 167px;
							background: #9BA7BB;
							margin-right: 12px;
						}

						.list-cont-box {
							flex: 1;

							h5 {

								font-size: 16px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #383D41;
							}

							p {

								font-size: 12px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #7C7E86;
							}

							p:nth-child(1) {
								margin-bottom: 15px;
							}

							p:nth-child(2) {
								margin-bottom: 7px;
							}

							.list-cont-box-btn {
								margin-top: 27px;

								span {

									font-size: 13px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #E03838;
								}

								button {
									width: 74px;
									height: 28px;
									background: #335ED9;
									font-size: 13px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #FFFFFF;
									line-height: 28px;
									text-align: center;
								}
							}
						}
					}

					// 首页 建筑资质服务全场景覆盖
					.business {
						.business-box {
							.business-box-video {
								width: 532px;
								height: 220px;
								background: #100F10;
								border: 1px solid #D9DCE8;
								margin-right: 9px;
								background: #000088;
								margin-bottom: 13px;
							}

							.business-box-list {
								width: 260px;
								height: 220px;
								background: #ffffff;
								margin-right: 10px;
								margin-bottom: 13px;

								.business-box-list-img {
									width: 260px;
									height: 146px;
								}

								h4 {

									font-size: 16px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #383D41;
									line-height: 22px;
								}

								span {

									font-size: 12px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #7C7E86;
									line-height: 22px;
								}
							}

						}

						.business-right {
							padding: 24px;
							width: 345px;
							height: 452px;
							// margin-left: 15px;
							background: #FFFFFF;

							.business-right-hread {
								// padding: 24px;
								font-size: 16px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #383D41;
								line-height: 22px;
								cursor: pointer;

								.tab-list {
									position: relative;
									height: 30px;

									span {
										position: absolute;
										bottom: 0;
										left: 50%;
										margin-left: -19px;
										width: 38px;
										height: 2px;
									}

									.span-status {
										background: #335ED9;
									}
								}
							}

							.business-right-cont {
								.business-right-cont-list {
									cursor: pointer;
									align-items: center;

									span {
										width: 6px;
										height: 6px;
										background: #335ED9;
										border-radius: 50%;
										margin-right: 10px;
									}

									p {
										font-size: 16px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #7C7E86;
										line-height: 22px;
									}
								}
							}
						}
					}

					.noDate {
						width: 100%;
						height: 100%;
						@include flex-center(column);
						color: #E5E5E5;

						img {
							width: 100px;

						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: 1024px) {
	    .home {
	        .home-data {
	            margin-top: 84px;
	            @include flex-between();
	
	            &-item {
	                @include flex-center(column);
	                width: 23%;
	
	                .img-box {
	                    @include flex-center();
	                    width: 100%;
	                }
	
	                img {
	                    width: 90%;
	                }
	
	            }
	        }
	
	        .home-business {
	
	
	            &-main {
	
	                .business-img {
	                    width: 48%;
	                }
	
	                .business-menu {
	                    width: 52%;
	                }
	            }
	        }
	
	        .home-scenes {
	            &-main {
	                .scenes-menu {
	                    .list {
	                        padding: 0;
	
	                        .item {
	                            padding: 0 12px 20px;
	                        }
	
	
	                    }
	
	                    .more {
	                        display: none;
	                    }
	                }
	
	
	            }
	        }
	
	        .home-policy {
	            margin-top: 60px;
	            display: flex;
	            justify-content: space-between;
	
	            &-main {
	                width: 70%;
	
	                .list {
	                    .item {
	                        .time {
	                            width: 25%;
							}
							.title{
								width: 75%;
							}
	                    }
	                }
	
	
	            }
	
	
	        }
	    }
	
	}
	
	@media only screen and (max-width: 750px) {
	
	        .home-scenes {
	            &-main {
	                .scenes-menu {
	                    @include flex-between();
	
	                    .list {
	                        width: 100%;
	                        padding: 0 20px;
	                        @include flex-start();
	                        border-bottom: 1px solid #E3E5EF;
	                        overflow-x: scroll;
	
	                        &::-webkit-scrollbar {
	                            display: none
	                        }
	
	
	                        .item {
	                            flex-shrink: 0;
	                            font-size: 26px;
	                        }
	
	                    }
	
	                    .more {
	                        display: none;
	                    }
	                }
	
	                .scenes-content {
	                    margin-top: 45px;
	                    flex-wrap: wrap;
	
	                    .scenes-img {
	                        width: 100%;
	                        height: 134px;
	                        overflow: hidden;
							background: url(../../../assets/image/94.jpg) no-repeat; 
	                        background-size: 100%;
	                        background-position: center center;
	
	                        img {
	                            opacity: 0;
	                            width: 100%;
	                            height: 100%;
	                        }
	                    }
	
	                    .list {
	                        height: auto;
	                        width: 100%;
	
	                        .item {
	                            margin-top: 20px;
	                            width: 100%;
	                            height: auto;
	
	                            &:hover {
	                                transform: scale(1);
	                            }
	
	                            .top {
	                                .title {
	                                    font-size: 26px;
	                                }
	
	                                .more {
	                                    font-size: 26px;
	                                    width: 147px;
	                                    height: 41px;
	
	                                }
	                            }
	
	                            .content {
	                                li {
	                                    font-size: 22px;
	                                }
	                            }
	                        }
	
	                        .noDate {
	                            margin-top: 40px;
	                        }
	                    }
	                }
	            }
	        }
	
	}
	
	@media only screen and (max-width: 481px) {
	        .home-scenes {
	            &-main {
	                .scenes-menu {
	                    @include flex-between();
	
	                    .list {
	
	
	                        .item {
	                            padding: 0 10px 10px;
	                            font-size: 14px;
	                        }
	
	                    }
	
	                    .more {
	                        display: none;
	                    }
	                }
	
	                .scenes-content {
	                    margin-top: 30px;
	
	                    .scenes-img {
	                        height: 86px;
							width: 100%;
							background: url(../../../assets/image/94.jpg) no-repeat; 
							background-size: 100%;
							background-position: 50%;
							margin-right: 0;
	                    }
	
	                    .list {
							
	                        .item {
	                            margin-top: 20px;
	                            width: 100%;
	                            height: auto;
	
	                            &:hover {
	                                transform: scale(1);
	                            }
	
	                            .top {
	                                .title {
	                                    font-size: 16px;
	                                }
	
	                                .more {
	
	                                    font-size: 16px;
	                                    width: 94px;
	                                    height: 26px;
	
	                                }
	                            }
	
	                            .content {
	                                li {
	                                    font-size: 14px;
	                                }
	                            }
	                        }
	
	                        .noDate {
	                            margin-top: 40px;
	                        }
	                    }
	                }
	            }
	        }

	}

</style>
