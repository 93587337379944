<template>
  <div class="questions">
    <div class="main-title">
      <h1>常见问题</h1>
    </div>
    <div class="questions-main">
      <div class="list p-3">
        <template v-for="(item, index) in questions">
          <div class="item" :key="index" @click="changeQuestions(index)">
            <p>{{ item.title }}</p>
          </div>
        </template>
      </div>
      <div class="questions-img">
        <video
          class="myVideo"
          :src="nowVideo"
          type="video/mp4"
          autoplay="autoplay"
          preload="none"
          controls="controls"
          playsinline="true"
          webkit-playsinline="true"
          x5-playsinline="true"
          x5-video-player-type="h5"
          style="object-fit: auto; display: inline"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nowVideo: null,
      active: 0,
      questions: [
        {
          title: "资质怎么办理？",
          video: require("../../../static/video/01.mp4"),
        },
        {
          title: "办一个资质多少钱？",
          video: require("../../../static/video/02.mp4"),
        },
        {
          title: "办理一个资质需要多久？",
          video: require("../../../static/video/03.mp4"),
        },
        {
          title: "办理资质我们需要什么条件？",
          video: require("../../../static/video/04.mp4"),
        },
        {
          title: "办资质我能接什么工程？",
          video: require("../../../static/video/05.mp4"),
        },
        {
          title: "申请建筑业企业资质，多长时间能知道审批结果？",
          video: require("../../../static/video/06.mp4"),
        },
        {
          title: "净资产如何认定？",
          video: require("../../../static/video/07.mp4"),
        },
        {
          title:
            "已具有设计或施工资质的企业，可以申请什么级别的设计施工一体化资质？",
          video: require("../../../static/video/08.mp4"),
        },
      ],
    };
  },
  methods: {
    changeQuestions(index) {
      this.active = index;
      this.nowVideo = this.questions[index].video;
    },
  },
  created() {
    this.nowVideo = this.questions[this.active].video;
  },
};
</script>

<style lang="scss">
.questions {
  &-main {
    @include flex-start();
    min-height: 214px;

    .list {
      width: 68%;
      background-color: #f9f9fb;
      @include flex-center();
      flex-wrap: wrap;

      .item {
        width: 50%;

        p {
          cursor: pointer;
          display: block;
          position: relative;
          padding: 0 20px;
          @include ellipsis();
          color: #727585;

          &:hover {
            color: $primary-color;
          }

          &::after {
            content: "";
            display: block;
            width: 5px;
            height: 5px;
            background: $primary-color;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
    }

    .questions-img {
      width: 32%;
      height: 214px;
      background: #000000;
      opacity: 1;

      .myVideo {
        width: 100%;
        height: 100%;
        outline: none;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .questions {
    &-main {
      flex-wrap: wrap-reverse;
      .list {
        width: 100%;
        .item {
          width: 100%;
          margin-bottom: 30px;
          &:nth-last-child(1) {
            margin: 0;
          }

          p {
            font-size: 24px;
          }
        }
      }

      .questions-img {
        width: 100%;
        height: 324px;
      }
    }
  }
}
@media only screen and (max-width: 481px) {
  .questions {
    &-main {
      .list {
        .item {
          margin-bottom: 10px;
          &:nth-last-child(1) {
            margin: 0;
          }

          p {
            font-size: 16px;
          }
        }
      }

      .questions-img {
        width: 100%;
        height: 200px;
      }
    }
  }
}
</style>
